.merrywrap {
    position: absolute;
    top: 40px;
    bottom: 40px;
    left: 40px;
    right: 40px;
}

.merrywrap svg path,
.merrywrap svg polygon,
.merrywrap svg circle {
    fill: none;
}

.giftbox {
    width: 300px;
    height: 220px;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -150px;
    z-index: 10;
    cursor: pointer;
}

.giftbox::after {
    content: 'Click me';
    position: absolute;
    color: #fff;

    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 24px;
    z-index: 1000;
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

.giftbox>div {
    background: #34495e;
    position: absolute;
}

.giftbox .cover {
    top: 0;
    left: 0;
    height: 25%;
    width: 100%;
    z-index: 2;
}

.giftbox .box {
    bottom: 0;
    height: 80%;
    left: 5%;
    right: 5%;
    z-index: 1;
}

.giftbox>div::after,
.giftbox>div::before {
    content: '';
    position: absolute;
    top: 0;
}

/* ribbon */
.giftbox>div::before {
    background: #fdc56d;
    width: 50px;
    left: 50%;
    height: 100%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

/* shadow */
.giftbox .box::after {
    background: rgba(0, 0, 0, 0.1);
    left: 0;
    height: 30px;
    width: 100%;
}

.giftbox .cover div {
    position: absolute;
    height: 60px;
    width: 60px;
    bottom: 100%;
    left: 50%;
    margin-left: -30px;
}

.giftbox .cover div::before,
.giftbox .cover div::after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background: transparent;
    border-radius: 30px;
    box-shadow: inset 0 0 0 14px #fdc56d;
}

.giftbox .cover div::before {
    -webkit-transform: translateX(-45%) skewY(40deg);
    transform: translateX(-45%) skewY(40deg);
}

.giftbox .cover div::after {
    -webkit-transform: translateX(45%) skewY(-40deg);
    transform: translateX(45%) skewY(-40deg);
}

/* Grid */
.icons {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    position: absolute;
}

.icons .row {
    width: 100%;
    height: calc(100% / 7);
}

.icons span {
    display: block;
    float: left;
    width: calc((100% / 11) - 1px);
    height: 100%;
    font-family: 'Peralta';
    font-size: 74px;
    text-transform: uppercase;
    text-align: center;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.5s ease-in, opacity 0.1s;
    transition: transform 0.5s ease-in, opacity 0.1s;
}

.icons span.letter {
    color: #fdc56d;
}

.icons svg path,
.icons svg polygon,
.icons svg circle {
    stroke: #fdc56d;
    stroke-width: 1;
}

/* Background */
.background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}

.background svg path {
    stroke: #fdc56d;
    stroke-width: 0.8;
}


/* Steps (animations) for giftbox */

.step-1 .giftbox {
    -webkit-animation: wobble 0.5s linear infinite forwards;
    animation: wobble 0.5s linear infinite forwards;
}

.step-2 .giftbox::after,
.step-3 .giftbox::after,
.step-4 .giftbox::after {
    opacity: 0;
}

.step-1 .giftbox .cover,
.step-1 .giftbox .cover div {
    -webkit-animation: wobble 0.5s 0.1s linear infinite forwards;
    animation: wobble 0.5s 0.1s linear infinite forwards;
}

.step-2 .giftbox .cover {
    -webkit-animation: flyUp 0.4s ease-out forwards;
    animation: flyUp 0.4s ease-out forwards;
}

.step-2 .giftbox .box {
    animation: fallDown 0.2s 0.05s ease-in forwards;
}

.step-3 .giftbox,
.step-3 .giftbox,
.step-4 .giftbox {
    opacity: 0;
    z-index: 1;
}




@-webkit-keyframes wobble {
    25% {
        -webkit-transform: rotate(4deg);
    }

    75% {
        -webkit-transform: rotate(-2deg);
    }
}

@keyframes wobble {
    25% {
        transform: rotate(4deg);
    }

    75% {
        transform: rotate(-2deg);
    }
}

@-webkit-keyframes flyUp {
    75% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-1000px) rotate(20deg);
        opacity: 0;
    }
}

@keyframes flyUp {
    75% {
        opacity: 1;
    }

    100% {
        transform: translateY(-1000px) rotate(20deg);
        opacity: 0;
    }
}

@-webkit-keyframes fallDown {
    75% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(100%);
        opacity: 0;
    }
}

@keyframes fallDown {
    75% {
        opacity: 1;
    }

    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}

@media screen and (min-height: 50em) {
    .icons span.letter {
        font-size: 110px;
    }
}

@media screen and (max-width: 52em) {
    .icons span.letter {
        font-size: 44px;
    }

    .step-3 .icons span.letter,
    .step-4 .icons span.letter {
        text-shadow: 2px 2px 0 #ca3121;
    }
}

@media screen and (max-width: 44em) {
    .icons span.letter {
        font-size: 18px;
        line-height: 80px;
    }

    .step-3 .icons span.letter,
    .step-4 .icons span.letter {
        text-shadow: 1px 1px 0 #ca3121;
    }

    .icons svg path,
    .icons svg polygon,
    .icons svg circle {
        stroke-width: 3;
    }
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800");
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
@import "./styles/index.scss";

* {
  font-family: "Montserrat";
}

p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.body {
  padding: 0 90px;
}

.App {
  text-align: center;
}

#root {
  height: 100%;
  width: 100%;
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 8px;
  padding: 8px 90px;
  height: 85px;

  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
}

.profile-section {
  display: flex;
  float: right;
}

.profile-section > .name {
  margin-right: 8px;
  align-self: center;
}

.name {
  color: darkslategray;
  margin-right: 8px;
}

.login-container {
  padding: 16px;
  width: 300px;
  border-radius: 3px;
  grid-column: 2;
  justify-self: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.login-container .ant-btn {
  float: right;
}

.game {
  grid-area: game;
  justify-content: center;
  max-width: 800px;
  height: 100%;
}

.header .left {
  display: flex;

  align-items: center;
  grid-column: 1;
}

.header .left:hover {
  cursor: pointer;
}

.header .right {
  display: flex;
  grid-column: 3;
  align-items: center;
  justify-content: flex-end;
  color: black;
}

.header .title {
  font-weight: bold;
  font-size: 20px;
  align-self: center;
}

button {
  width: 100%;
  height: 40px !important;
}

button.signup {
  width: 80px;
  background-color: #ff5f3b !important;
  border-color: transparent;
  margin-left: 16px;
  font-weight: bolder;
  box-shadow: inset -2 0 2px #000000;
}

button.signup:hover {
  border-color: transparent;
}

button.login {
  width: 80px;
  color: black !important;
  border-color: #ff5f3b !important;
}

.description {
  padding: 16px;
  font-weight: 600;
}

.column1 {
  width: 60px;
  padding-left: 8px;
}

.column1 span {
  padding-left: 8px;
}

.column2 {
  max-width: 140px;
  padding-left: 16px;
  margin-right: 100px;
}

.column3 {
  padding-right: 32px;
  text-align: right;
}

.column2 span {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.scoreboard-table thead {
  font-weight: bold;
}

.scoreboard-table tbody tr:nth-child(2) {
  background-color: rgba(0, 0, 0, 0.1);
}

.scoreboard-table tr {
  height: 40px;
}

@keyframes moveup {
  100% {
    margin-top: 0px;
  }
}

.character-preview.move-up {
  margin-top: 0px;
  transition: margin-top 0.8s;
}

.character-preview {
  width: 150px;
  height: 180px;
  padding: 16px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  margin-left: calc(50% - 100px);
  margin-top: 320px;
  position: absolute;
}

.preview-name {
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  position: absolute;
  bottom: 30px;
}

.character-preview img {
  justify-content: center;
  margin-left: 20px;
  margin-bottom: 24px;
}

.home-container {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
}

.body-grid {
  margin-top: 60px;
  display: grid;
  grid-column-gap: 16px;
}

.container-header {
  padding: 10px;
  text-align: center;
  font-weight: bolder;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.game-container {
  background-color: #f2f2f2;
  border-radius: 5px;

  padding: 4px;
}

.scoreboard-container {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 2px;
  max-width: 600px;
}

.container .content {
  padding: 32px;
}

.container.shop {
  min-height: 350px;
  margin: 16px 0;

  background-color: #f2f2f2;
  border-radius: 5px;
  grid-area: shop;
}

.scoreboard {
  max-width: 600px;
}

@media (max-width: 940px) {
  .body {
    padding: 0 16px;
  }

  .body-grid {
    grid-template-columns: 1fr;
    display: block;
    margin-top: 8px;
  }

  .game-container {
    margin: 16px 0;
  }

  .scoreboard-container {
    max-width: 100%;
    margin: 16px 0;
  }

  .scoreboard {
    max-width: 100%;
  }

  .header .title {
    display: none;
  }

  .header {
    padding: 8px 24px;
  }

  .container-header {
    background-color: none;
    color: #272d34;
  }
}

@media (min-width: 940px) {
  .body-grid {
    margin-top: 60px;
    grid-template-columns: 0px 1fr 1fr auto;
    grid-template-areas:
      "character game game score"
      "shop shop shop shop";
  }

  .game-container {
    grid-area: game;
  }

  .scoreboard-container {
    grid-area: score;
  }

  .container-header {
    color: white;
    background-color: #272d34;
  }
}

.ant-modal-title {
  text-align: center !important;
  font-weight: bolder !important;
  font-size: 18px !important;
}

.ant-btn-primary {
  color: #fff;
  background: #ff5f3c !important;
  border-color: #ff5f3c !important;
}

.ant-btn-background-ghost.ant-btn-primary {
  background: transparent !important;

  text-shadow: none;
}

.btn-login-register {
  font-weight: 600 !important;
}

.text-btn-login-register span {
  padding-top: 16px;
  text-decoration: underline !important;
  font-weight: 600;
}

.ant-modal-content {
  border-radius: 10px !important;
}

.ant-modal-body {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 700px;
}

.ant-modal-header {
  border-radius: 10px !important;
}

label {
  font-weight: 600;
}

.container-content {
  display: grid;
}

.login-to-play {
  font-size: 20px;
  text-align: center;
  position: absolute;
  color: white;
  width: 310px;
  top: 0;
  padding: 12px;
  border-radius: 10px;
  left: 50%;
  top: 40%;
  font-weight: 600;
  font-size: 24px;
}

.login-to-play p {
  position: absolute;
  left: -50%;
}

.login-to-play u:hover {
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.unlocked {
  font-weight: bold;
  text-align: center;
  opacity: 1;
  animation: fadeIn 1s;
}

.access-denied {
  padding-top: 45vh;
  text-align: center;
  display: flex;
  justify-items: center;
  align-self: center;
  justify-content: center;
}

.admin-dashboard {
  height: calc(100% - 70px);
  grid-template-columns: 250px auto;
  column-gap: 16px;
  display: grid;
}

.sidebar {
  height: 100%;
  box-shadow: 2px 0 5px -2px #888;
  grid-column: 1;
}

.tab {
  padding: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.tab:hover {
  cursor: pointer;
}

.tab.active {
  background-color: #ff5f3b;
  color: white;
}

.disabled {
  opacity: 0.6;
  cursor: block;
}

.tab-content {
  padding: 16px;
  grid-column: 2;
}

.create-character-btn {
  width: 200px;
}

.character-tiny {
  transform: scale(2);
  image-rendering: pixelated;
  height: 0px;
  display: block;
}

.character-item:hover {
  cursor: pointer;
}

.character-tile img {
  padding: 18px 0 0 0;
  margin-top: -32px;
  margin-left: 15px;
  width: 16px;
  height: 0px;
}

.character-tile:hover {
  cursor: pointer;
}

.character-item {
  padding: 16px;
}

.character-item.active {
  border: 2px solid #ff5f3b;
  border-radius: 5px;
}

.df {
  display: flex;
}

.game-preview {
  position: relative;
  display: flex;
  justify-self: center;
}

.loading-gif {
  image-rendering: pixelated;
  transform: scale(2);
  position: relative;
  left: -50%;
}

.personal-score-row {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.online {
  width: 7px !important;
  height: 7px;
  border-radius: 20px;
  margin-left: 4px;
  margin-top: 20px;
  background-color: limegreen;
}

.wealth {
  color: darkslategray;
  font-weight: bold;
  padding-right: 32px;
  position: relative;
}

.wealth p {
  position: absolute;
  left: -20px;
  top: -8px;
}

.blob-currency {
  left: -40px;
  position: absolute;
  image-rendering: pixelated;
  transform: scale(2);
}

.flyingblob-popup-modal .ant-modal-header {
  background-color: #272d34;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.flyingblob-popup-modal .ant-modal-content {
  background-color: #f2f2f2;
}

.flyingblob-popup-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
  color: white;
}

.ant-modal-close-x {
  float: right;
}

.flyingblob-popup-modal .ant-modal-header .ant-modal-title {
  color: white;
}

.flyingblob-popup-modal .content .description {
  display: flex;
  justify-content: center;
}

.flyingblob-popup-modal .content p {
  width: 300px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  font-size: 16px;
}

.shop-item {
  height: 170px;
  width: 200px;
  margin: 12px;
  display: grid;
  position: relative;
  grid-template-areas:
    "preview"
    "info";
  grid-template-rows: auto 40px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.shop-item:hover {
  cursor: pointer;
}

.hovering {
  filter: brightness(0.3);
  transition: 0.1s ease-in-out;
}

.shop .content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

.shop-item .preview {
  grid-area: preview;
  justify-content: center;
  align-items: center;
  background: white;
  display: flex;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.shop-item .info {
  display: flex;
  justify-content: space-between;
  grid-area: info;
  font-weight: bold;
  align-items: center;
  background-color: #272d34;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.shop-item .info .name {
  font-weight: 600;
  padding-left: 12px;
  color: white;
}

.shop-item .info .price {
  float: right;
  display: flex;
  position: relative;
  color: white;
  margin-right: 20px;
}

.shop-item .info .price img {
  position: absolute;
  right: -10px;
  top: 6px;
}

.buy-text {
  color: white;
  font-size: 22px;
  font-weight: bold;
  position: absolute;
  left: 40%;
  top: 35%;
}

.shop-item .limited {
  position: absolute;
  font-weight: bold;
  background-color: #ff5f3c;
  color: white;
  margin-top: px;
  margin-right: -18px;
  padding: 4px 16px;
  right: 0px;
  transform: rotate(35deg);
  border-top-left-radius: 90px;
  border-top-right-radius: 70px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 7px;
}

.shop-loader {
}

.tool-tile {
  position: sticky;
  grid-area: character;
  margin-left: -70px;
  top: 60px;
  width: 50px;
  height: 50px;
  border: 1px solid #ffffff;
  background-color: #f2f2f2;
  border-radius: 3px;
  padding-top: 50px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  margin-bottom: 16px;
}

.tool-tile:hover {
  cursor: pointer;
}

.tool-img {
  filter: contrast(0.5);
  padding: 18px 0 0 0;
  margin-top: -92px;
  margin-left: 10px;
  width: 28px;
}

#toolbar {
  position: sticky;
  top: 130px;
}

.challenge-item {
  display: grid;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
  padding: 16px;
  margin: 16px 0;
}

.challenge-item .players {
  font-weight: bold;
  grid-column: 1;
}

.challenge-item .name {
  grid-row: 1;
  grid-column: 1;
  color: black;
  font-weight: bold;
  overflow-wrap: anywhere;
}

.challenge-item button {
  grid-column: 2;
  width: 110px;
  justify-self: end;
}

.challenge-item .button-group {
  display: flex;
  grid-column: 2;
  justify-content: flex-end;
}

.challenge-item .button-group .ant-btn:first-child {
  margin-right: 16px;
}

.challenge-item .info {
  grid-row: 2;
  grid-column: 1;
  font-weight: 600;
  color: #9a9a9a;
}

.challenge-item img {
  justify-self: end;
}

.challenge-item .initiator {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  justify-content: flex-end;
}

.initiator .username {
  font-weight: 600;
  margin-left: 4px;
}

button.secondary {
  background-color: #2bacc9;
  font-weight: 600;
  color: white;
  border-radius: 3px;
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.25);
}

button.btn-create {
  width: 150px;
  font-weight: 600;
}

#create_challenge {
  padding: 32px;
}

.input-button button {
  width: 100px;
  margin-left: 16px;
}

.invited-user {
  width: 250px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
  animation: fadeIn 1s;
  display: flex;
  justify-content: space-between;
}

.invited-user span {
  color: red;
}

.hover:hover {
  cursor: pointer;
}

.challenge-item .chances {
  grid-row: 2;
  grid-column: 2;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  font-weight: 400;
}

.email-invitation-message {
  animation: fadeIn 0.5s;
  margin-bottom: 16px;
}

.display-none {
  display: none;
}

.center {
  text-align: center;
}

.absolute.center {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 50%;
}

.white {
  color: white;
}
